<template>
  <Layout>
    <PageHeader :title="state.title" :items="state.items" />
    <div class="card rounded-3">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h6 class="fs-16 mb-0">{{ $t('playDiscs.list-discs') }}</h6>
        <b-button variant="dark" class="waves-effect waves-light w-sm pt-2 pb-2"
          @click="navigateTo('/admin/play-discs/create')">
          <span class="fs-12">{{ $t('t-create') }}</span>
        </b-button>
      </div>
      <div class="table-responsive">
        <TableCommon ref="tablePlayDiscs" :columns="state.columns" :query="{ role: ROLES.ADMIN }"
                     :endpoint="`${ADMIN_API_PATH.ADMIN_PLAY_LIST}`" @onEdit="redirectToEditPlaylist" @onDelete="deletePlaylist" />
      </div>

    </div>

  </Layout>
</template>

<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import router from "@/router";
import TableCommon from "@/components/common/TableCommon.vue";
import { reactive } from "vue";
import i18n from "@/i18n";
import { ROLES } from "@/constants";
import { ADMIN_API_PATH } from "@/constants";
import { get } from "lodash";
import Swal from "sweetalert2";
import { adsService } from "@/services";

export default {
  name: "index",
  components: {
    Layout,
    PageHeader,
    TableCommon
  },
  setup() {
    const state = reactive({
      title: i18n.global.t('playDiscs.list-discs'),
      items: [
        {
          text: i18n.global.t('t-ads-management'),
          active: true
        },
        {
          text: i18n.global.t('playDiscs.list-discs'),
          active: true,
        },
      ],
      columns: [
        {
          sortable: false,
          label: '',
          type: 'stt',
          maxWidth: 200
        },
        {
          key: 'uuid',
          sortable: true,
          label: i18n.global.t('playDiscs.play-discs-id'),
          maxWidth: 200
        },
        {
          key: 'name',
          sortable: true,
          label: i18n.global.t('playDiscs.play-discs-name'),
          maxWidth: 200
        },
        {
          key: 'count',
          sortable: true,
          label: i18n.global.t('playDiscs.number-of-facility'),
          maxWidth: 200
        },
        {
          key: 'quantity',
          sortable: true,
          label: i18n.global.t('playDiscs.number-of-ads'),
          maxWidth: 200
        },
        {
          key: 'totalTime',
          dynamicText: (row) => `${get(row, 'totalTime', '')} 秒`,
          sortable: true,
          label: i18n.global.t('playDiscs.play-time'),
          maxWidth: 200
        },
        {
          key: 'createdAt',
          type: 'datetime',
          sortable: true,
          label: i18n.global.t('t-registration-date'),
          maxWidth: 200
        },
        {
          key: 'updatedAt',
          type: 'datetime',
          sortable: true,
          label: i18n.global.t('t-last-updated'),
          maxWidth: 200
        },
        {
          key: 'action',
          options: ['edit', 'delete'],
          type: 'action',
          sortable: false,
          label: ''
        }
      ]
    });

    const navigateTo = (routerName) => {
      router.push(routerName)
    }

    const redirectToEditPlaylist = (id) => {
      router.push({
        name: "play-discs-update", params: {
          playlistId: id
        }
      })
    }

    return {
      ADMIN_API_PATH,
      state,
      ROLES,
      navigateTo,
      redirectToEditPlaylist
    }
  },

  methods: {
    async deletePlaylist(value) {
      const { id, name } = value;

      Swal.fire({
        text: i18n.global.t('deleted_message', { message: name || '' }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: i18n.global.t('yes'),
        cancelButtonText: i18n.global.t('cancel'),
      })
        .then(async (result) => {
          if (result.value) {
            try {
              await adsService.deletePlaylist(id);
            } catch (e) {
              console.log(e, 'delete account error')
            } finally {
              this.$refs.tablePlayDiscs.refreshTable();
            }
          }
        });
    }
  }
}
</script>

<style lang="scss" scoped >
.border-top {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border: 0.5px solid var(--vz-border-color);
  border-radius: 10px 10px 0 0;
}

.custom-padding {
  padding: 1rem !important;
  max-height: 57px !important;
}
</style>
